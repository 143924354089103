import Layout from "../../components/Layout";
import React from "react";
import EducatorCategoryList from "../../components/EducatorCategoryList";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Student Materials Guide Category | Educator Resources";
  const description =
    "Comb through our student materials to find and meet your child’s needs! You know your unique learner best, so we encourage you to tailor these quality resources to perfectly match your child.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Student Materials Guide</h1>
            <p>{description}</p>
            <EducatorCategoryList category="student-materials-guide" />
          </div>
        </div>
      </section>
    </Layout>
  );
};
